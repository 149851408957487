import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { capitalize, startCase } from 'lodash';

import Layout from '../components/layout';
import Head from '../components/head';

import styles from './listpage.module.scss';

const MakeModelsPage = ({
  data: {
    allContentfulVehicle: { nodes: allVehicles },
  },
  pageContext: { make: makeFilter, model: modelFilter, ordering },
}) => {
  let orderedVehicles = [];

  let inOrder = [];

  ordering.forEach((thisPlate, i) => {
    const match = allVehicles.filter(
      ({ numberPlate }) => thisPlate === numberPlate
    );

    if (match) {
      inOrder.push(...match);
    }

    if (i + 1 >= ordering.length) {
      orderedVehicles = inOrder.filter(
        ({ make, model }) =>
          make.trim().toLowerCase() === makeFilter &&
          model.trim().toLowerCase() === modelFilter
      );
    }
  });

  return (
    <Layout>
      <Head
        title={`${
          makeFilter === 'bmw' ? 'BMW' : startCase(makeFilter)
        } ${startCase(modelFilter)}`}
        description={`A gallery of ${
          makeFilter === 'bmw' ? 'BMW' : startCase(makeFilter)
        } ${startCase(
          modelFilter
        )}s parked in Hollingdean, blocking local residents from being able to park on their own streets.`}
        noIndex={true}
      />

      <main className={styles.home}>
        <h2>
          {makeFilter === 'bmw' ? 'BMW' : startCase(makeFilter)}{' '}
          {startCase(modelFilter)} in Hollingdean
        </h2>
        <div className={styles.content}>
          <p>
            Here, you will find only{' '}
            {makeFilter === 'bmw' ? 'BMW' : startCase(makeFilter)}{' '}
            {startCase(modelFilter)} cars, all causing additional congestion,
            noise, and pollution to the residents of Hollingdean.
          </p>
        </div>

        <ul className={styles.grid}>
          {orderedVehicles.map(
            (
              {
                make,
                model,
                colour,
                numberPlate,
                gridImage: {
                  localFile: {
                    childImageSharp: { gatsbyImageData },
                  },
                },
              },
              i
            ) => (
              <li key={`allVehicles-${i}`} className={styles.item}>
                <Link
                  to={`/gallery/${numberPlate
                    .replace(' ', '-')
                    .toLowerCase()}/`}
                  className={styles.link}
                  aria-label={`View ${numberPlate}, a ${colour} ${make} ${model} parked in Hollingdean`}
                >
                  <GatsbyImage
                    image={gatsbyImageData}
                    alt={`${numberPlate}, a ${colour} ${make} ${model} parked in Hollingdean`}
                    placholder="dominantColor"
                    loading="lazy"
                  />
                  <h2 className={styles.numberplate}>{numberPlate}</h2>
                </Link>
              </li>
            )
          )}
        </ul>
      </main>
    </Layout>
  );
};

export const query = graphql`
  query MakeModelsPage {
    allContentfulVehicle {
      nodes {
        id
        numberPlate
        colour
        make
        model
        gridImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 400
                quality: 40
                placeholder: DOMINANT_COLOR
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
  }
`;

export default MakeModelsPage;
